@tailwind base;
@tailwind components;
@tailwind utilities;

/*body {*/
/*    font-family: sans-serif;*/
/*    background-color: var(--tg-theme-bg-color, #ffffff);*/
/*    color: var(--tg-theme-text-color, #222222);*/
/*    font-size: 16px;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    color-scheme: var(--tg-color-scheme);*/
/*}*/